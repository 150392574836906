.progress {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 16px;
    margin: 0;
    background-color: #fff;
    z-index: 9999;
    position: fixed;
    right: 0;
    top: 60px;

    &__done {
      background-color: #52b36b;
      max-width: 100%;
      height: 100%;
      padding: 0 24px;
      transition: 0.5s ease 0.8s;

      &__label {
        display: flex;
        &__text {
          color: #fff;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
        }

        &__text::after {
          overflow: hidden;
          display: inline-block;
          vertical-align: bottom;
          margin-left: 2px;
          -webkit-animation: ellipsis steps(4, end) 900ms infinite;
          animation: ellipsis steps(4, end) 900ms infinite;
          content: '\2026'; /* ascii code for the ellipsis character */
          width: 0px;
        }
      }
    }
  }
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
