.tooltip {
  color: #ffff;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
}
.print {
  * {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
  }
}
.block {
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 20px 25px 20px 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.title {
  display: flex;
  color: #282828;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  justify-content: space-between;
  align-items: center;
}
.margin {
  margin: 4px;
}
.subTitle {
  color: #232b95;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.chart {
  // margin-bottom: 4px;
  margin-bottom: 4px;
  width: 100%;
  min-height: 256px;
  // display: flex;
  height: 256px;
  align-items: center;
  position: relative;
}
