@import '../../../../styles/config/config.scss';

.line {
  border-bottom: 1px solid #eff2f7;
  margin-bottom: 28px;
}
.gauge {
  &__line {
    margin-top: 4px;
    float: right;
    width: 51px;
    border-bottom: solid #7bd5a7;
  }
  &__dotted {
    margin-top: 4px;
    float: right;
    width: 51px;
    border-bottom: dashed #7bd5a7;
  }
  &__wrapper {
    height: 100%;
    min-height: 408px;
    background: #ffffff;
    border-radius: 4px;
    padding: $base * 6 $base * 9;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    &__secondary {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #7a89a7;
      margin-top: 8px;
    }
    &__label {
      text-align: left;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #282828;

      display: flex;
      flex-direction: column;
      &__subText {
        margin-top: 4px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #7a89a7;
      }
      &__text {
        font-family: 'Open Sans';
        text-align: left;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: $primary-text-color;
        line-height: 22px;
      }
    }
  }
  &__chart {
    height: 188px !important;
  }
  &__stats {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    background: rgba(239, 242, 247, 0.4);
    &__line {
      border-top: 1px solid #eff2f7;
      margin-bottom: 14px;
    }
    &__LID {
      height: 128px;
      padding: 16px 22px 0;

      &__label {
        font-family: 'Open Sans';
        letter-spacing: 0.2px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #282828;
      }
      &__value {
        margin-top: $base * 3.5;
        margin-bottom: $base * 3.5;
        color: #282828;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 49px;
      }
    }
    &__CDFI {
      height: 120px;
      padding: $base * 3 $base * 4 $base * 4;
      &__label {
        font-family: 'Open Sans';
        letter-spacing: 0.2px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #282828;
      }
      &__value {
        margin-top: $base * 2;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
      }
    }
  }
}

.gauge * {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
}
.svg {
  display: block;
  margin: 20px auto;
  max-width: 100%;
}

.svg-circle-bg {
  transition: stroke-dashoffset 850ms ease-in-out;
  fill: none;
}

.svg-circle {
  fill: none;
}

.svg-circle-text {
  font-size: 2rem;
  text-anchor: middle;
  fill: #fff;
  font-weight: bold;
}

.chart {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    &__pie {
      height: 270px !important;
    }
    &__legend {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &__row {
        padding: 4px;
        height: 100%;
        max-height: 50px;
        display: flex;
        flex-direction: row;
        align-content: flex-end;
        justify-content: space-around;
        align-items: center;
        &__text {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #282828;
          margin-left: 10px;
        }
        &__circle {
          width: 12px;
          display: inline-block;
          height: 12px;
          border-radius: 50%;
          -moz-border-radius: 50%;
          margin-top: 4px;
          -webkit-border-radius: 50%;
        }
      }
    }
  }
}
