.wrapper {
  margin-top: 20px;
}
.chartSubTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #282828;
  &__margin {
    margin-left: 6px;
  }
}
.info {
  margin-top: 28px;
  &__age {
    display: flex;
    flex-direction: row;
    &__image {
      margin: 8px 10px 0px 0px;
    }

    &__postfix {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #7a89a7;
    }
    &__prefix {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 49px;
      align-items: center;
      color: #282828;
      margin-right: 4px;
    }
  }
  &__descriptionText {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #282828;
    margin-bottom: 2px;
  }
  &__label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 49px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #282828;
  }
  &__data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    width: 200px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #232b95;
  }
}
