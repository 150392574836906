.wrapper {
  height: 181px;
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 24px 24px 24px 38px;
  width: 100%;
  margin-bottom: 28px;
  &__container {
    height: auto;
    margin: 0 auto;
    padding: 10px;
    position: relative;
  }
}

.noData {
  &__wrapper {
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 8px 30px 18px;
    max-height: 198px;
    width: 100%;
    margin-bottom: 28px;
  }
  &__title {
    margin-bottom: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.3px;
    color: #282828;
  }
  &__text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #7a89a7;
    width: 100%;
    max-width: 692px;
    margin-bottom: 16px;
  }
  &__btn {
    height: 40px;
    width: 210px;
  }
}
.item {
  // top: -20px;
  display: block;
  &__title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.3px;
    color: #282828;
    margin-bottom: 8px;
  }
  &__heading {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.3px;
    color: #282828;
    margin-top: 25px;
  }
  &__text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #282828;
  }
  &__subtext {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;

    &__list {
      margin: 0;
      margin-top: 28px;
      padding: 0;
      list-style: none;
      position: relative;
    }
    &__list li::before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      background-color: #52b36b;
      margin-right: 16px;
      border-radius: 50%;
      position: absolute;
      right: 100%;
      top: 30%;
    }

    &__list ul li {
      font-size: 18px;
    }

    &__primary {
      color: #232b95;
    }
    &__secondary {
      color: #52b36b;
    }
  }
}
