@import '../../../styles/config/config.scss';

.widget {
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 100%;
  padding: $base * 6 $base * 11.5;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  &__currentSubmission {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: #282828;
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__value {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #282828;
  }
  &__lastSubmission {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #7a89a7;
    text-align: left;
  }
  &__percent {
    font-family: 'Open Sans';
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
  }
  &__tittle {
    font-family: 'Open Sans';
    font-style: normal;
    vertical-align: text-bottom;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $primary-text-color;
    &__tooltip {
      margin: $base;
    }
  }
  &__vertical {
    width: 100%;
    margin-top: $base * 8;
    // align-items: center;
    // justify-content: center;
  }
}
.widget * {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
}
