@import '../../../../styles/config/config.scss';

.toastr {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 9999999;
  display: flex;
  max-width: 558px;
  width: 100%;
  min-height: 88px;
  max-height: 154px;
  padding: 16px;
  flex-direction: row;
  justify-content: space-between;

  &__warring {
    background: #fef3e2;
    border: 1px solid #ef8031;
    border-radius: 8px;
  }
  &__success {
    background: #eef7f0;
    border: 1px solid #52b36b;
    border-radius: 8px;
  }
  &__error {
    border: 1px solid #be2727;
    border-radius: 8px;
    background: #fae8e8;
  }
  &__icon {
    margin-right: 16px;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    &__header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &__title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: $primary-text-color;
      }
      &__closeBtn {
        background: none;
        border: none;
        color: #ffffff;
        font-weight: 900;
        outline: none;
        cursor: pointer;
        border-radius: 8px;
        padding: 4px 8px;
      }
      &__closeBtn:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    &__body {
      margin: 8px 0 0 0;
      padding: 0;
      width: 95%;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $primary-text-color;
    }
  }
}

@keyframes slide-in {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
