// .loader {
//   &__left {
//     animation: loading-bar-morph 3s 1s infinite;
//   }
//   &__middle {
//     animation: loading-bar-morph 3s 2s infinite;
//   }
//   &__right {
//     animation: loading-bar-morph 3s 1.5s infinite;
//   }
// }
// @keyframes loading-bar-morph {
//   15% {
//     opacity: 0;
//   }
//   35% {
//     opacity: 1;
//   }
//   65% {
//     opacity: 1;
//   }
//   85% {
//     opacity: 0;
//   }
// }
.loader {
  &__left {
    animation: loading-bar-morph 3s linear 1.8s infinite;
    transform-origin: center;
  }
  &__middle {
    animation: loading-bar-morph 3s linear 2.8s infinite;
    transform-origin: center;
  }
  &__right {
    animation: loading-bar-morph 3s linear 2.4s infinite;
    transform-origin: center;
  }
}
@keyframes loading-bar-morph {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
