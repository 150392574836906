.select {
  width: 438px;
  height: 36px;
  border-radius: 4px;
  display: inline-flex;
  background-color: #fff;
  border: 1px solid #7a89a7;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
  &__icon {
    transition: transform 0.5s ease;
  }
  &__value {
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    &__grey {
      color: #7a89a7;
    }
    &__blue {
      color: #232b95;
    }
  }

  [data-state='open'] &__icon {
    transform: rotate(180deg);
  }

  [data-state='closed'] &__icon {
    transform: rotate(0deg);
  }
  &__content {
    width: 460px;
    margin: 4px 0;
    border-radius: 4px;
    background: #fff;
    border: 1px solid rgba(122, 137, 167, 0.2);
    border-bottom: transparent;
    &__item {
      cursor: pointer;
      transition: transform 0.5s all;
      &:hover {
        background: #f2f3f6;
      }
      border-bottom: 1px solid rgba(122, 137, 167, 0.2);
      background: #fff;
      width: 100%;
      padding: 16px;
      &__title {
        color: #282828;
        font-family: 'Open Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        &__subTitle {
          color: #232b95;
          font-family: Open Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
      }
      &__margin {
        margin-bottom: 8px;
      }
      &__text {
        color: #282828;
        font-family: 'Open Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
