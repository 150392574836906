.dropdown {
  user-select: none;
  position: relative;
  &__btn {
    height: 40px;
    padding: 0 20px 0 8px;
    background: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    border: 1.5px solid #c2cee0;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #282828;
    &__icon {
      color: #7a89a7;
      font-size: 12px;
    }
  }
  &__content {
    margin-top: 8px;
    position: relative;
    border: 1.5px solid #c2cee0;
    box-sizing: border-box;
    border-radius: 4px;
    z-index: 5000;
    top: 105%;
    left: 0;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    font-family: 'Open Sans';
    width: 100%;
    height: 100%;
    max-height: 216px;
    overflow-y: auto;
    &__item {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #282828;
      padding: 10px;
      cursor: pointer;
      transition: all 0.2s;
    }
    &__item:hover {
      background: #f4f4f4;
    }
  }
}
