.container {
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 100%;
  max-height: 78px;
  margin-bottom: 20px;
  &__content {
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &__title {
      display: flex;
      flex-direction: column;
      padding-left: 14px;
      &__text {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #282828;
      }
      &__subText {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #7a89a7;
      }
    }
    &__value {
      &__text {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 49px;
        text-align: right;
        color: #282828;
      }
    }
  }
}
