.noData {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 216px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 30px;
  &__title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #282828;
    margin-bottom: 16px;
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #7a89a7;
      width: 100%;
      margin-bottom: 6px;
      padding: 4px;
    }
    &__buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
      button {
        margin-right: 8px;
        margin-top: 8px;
        a {
          font-family: 'Open Sans';
          font-size: 14px;
          line-height: 22px;
          color: #2b39b9;
          text-decoration: none;
        }
      }
    }
  }
}

.benefits {
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 216px;
  padding: 20px 25px;
  margin-bottom: 20px;
  display: flex;
  &__mainValue {
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 87px;
    display: flex;
    align-items: center;
    color: #282828;
    align-content: baseline;
    flex-direction: column;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  &__block {
    margin-left: 34px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: space-around;
  }
  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #282828;
  }
  &__member {
    font-family: 'Open Sans';
    font-style: normal;
    &__value {
      font-size: 48px;
      font-weight: 700;
      align-self: flex-start;
      color: #282828;
      width: 100%;
    }
    &__info {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #232b95;
    }
  }
}
