.button {
  background: transparent;
  gap: 4px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  text-decoration: none;

  &__text {
    text-decoration: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #2b39b9;
  }
}

.button:hover {
  text-decoration: none;
  border-radius: 4px;
  filter: drop-shadow(0px 2px 15px rgba(122, 137, 167, 0.25%));
  background: #eaeaea;
}
