.informationBlock {
  width: 100%;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  &__text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.3px;
    margin: 0;
    color: #232b95;
    padding: 24px;
  }
  &__area {
    color: #52b36b;
  }
  &__impact {
    color: #282828;
  }
}
