.widgetContainer {
  padding: 9px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 100%;
}
.legend {
  margin-top: 24px;
  max-height: 92px;
  min-height: 86px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  &__wrapper {
    padding: 8px 24px;
    width: calc(100% - 80px);
    height: 100%;
    background: rgba(194, 206, 224, 0.1);
    border-radius: 4px;
    display: flex;
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      &__title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #282828;
      }
      &__text {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #7a89a7;
        margin-left: 20px;
      }
      &__line {
        width: 90px;
        min-width: 90px;
        max-width: 90px;
        height: 0;
        &__solid {
          border-bottom: 1.7px solid;
          height: 0px;
          background-image: none !important;
        }
        &__dash {
          height: 2px;
          background-image: linear-gradient(
            to right,
            #7a89a7 0%,
            #7a89a7 50%,
            transparent 50%
          );
          background-size: 16px 26px;
          background-repeat: repeat-x;
        }
        &__red {
          border-color: #be2727;
          background-image: linear-gradient(
            to right,
            #be2727 0%,
            #be2727 50%,
            transparent 50%
          );
        }

        &__orange {
          border-color: #f9a31d;
          background-image: linear-gradient(
            to right,
            #f9a31d 0%,
            #f9a31d 50%,
            transparent 50%
          );
        }
        &__green {
          border-color: #5cb082;
          background-image: linear-gradient(
            to right,
            #5cb082 0%,
            #5cb082 50%,
            transparent 50%
          );
        }
        &__grey {
          border-color: #7a89a7;
          background-image: linear-gradient(
            to right,
            #7a89a7 0%,
            #7a89a7 50%,
            transparent 50%
          );
        }
      }
    }
  }
}
.chart {
  height: 100%;
  transition: height 500ms ease;
  &__header {
    padding: 24px 40px 0;
    display: flex;
    justify-content: space-between;
    &__item {
      display: flex;
      align-items: center;
      &__title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #282828;
      }
      &__legend {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
      }
    }
  }
  &__main {
    width: 100%;
    height: 400px;
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-top: -16px;
  }
}
