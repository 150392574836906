@import '../../../styles/config/config.scss';

.statusElement {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: $base * 12;
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  width: 100%;
  display: flex;

  &__is_false {
    background-color: $brand-red-color;
  }
  &__is_true {
    background-color: $base-green-color;
  }
  &__text {
    margin-left: auto;
    margin-right: auto;
    padding: $base * 2;
    &__status {
      padding: $base;
      font-size: 16px;
      line-height: 22px;
      font-weight: $fw-medium;
    }
  }
}
