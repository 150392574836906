@import '../../../styles/config/config.scss';

.loading {
  display: flex;
  max-width: 300px;
  height: 530px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__title {
    color: #373935;
    margin: 22px 0 14px 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
  &__text {
    color: rgba(55, 57, 53, 0.53);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 0;
  }
}
.aries {
  color: #2b39b9;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  text-decoration-line: underline;
  border-radius: 4px;
  background: rgba(175, 184, 202, 0.15);
  width: 100%;
  height: 38px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0 20px 0;
}

.ariesButton {
  color: #2b39b9;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  transition-property: all;
  transition-duration: 0.25s;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

.ariesButton:hover {
  color: #ccc;
}

.disable {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  left: 0;
  text-align: center;
  background: rgba(204, 204, 204, 0.2);
  z-index: 99;
  align-items: center;
  justify-content: space-around;
}

.line {
  border-top: 1px solid #eff2f7;
  margin-top: 24px;
}
.modal {
  &__validation {
    &__wrapper {
      margin-top: 14px;
      background: rgba(217, 75, 75, 0.1);
      border-radius: 4px;
      padding: 0 14px;
      height: 40px;
      display: flex;
      &__message {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #d94b4b;
      }
    }
    &__message {
      margin-left: 9px;
      position: relative;
      color: #d01e1e;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
  }
  &__body {
    margin-bottom: 8px;
    &__item {
      display: flex;
      flex-direction: column;
      &__block {
        &__icon {
          margin-left: 8px;
          position: relative;
          top: -1px;
        }
      }
    }
  }
  &__dropdown {
    // height: 100%;
    margin-top: 20px;
    z-index: 232323;
    background: white;
    height: 82px;
    &__item {
      margin-bottom: 8px;
    }
  }
  &__heading {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    font-family: 'Open Sans';
    color: #282828;
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    font-family: 'Open Sans';
    color: #282828;
    margin-top: $base * 4;
    margin-bottom: $base * 3;
    display: flex;
    &__subText {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #7a89a7;
      margin-bottom: 2px;
    }
    &__step {
      background: #2b39b9;
      border-radius: 0.8em;
      -moz-border-radius: 0.8em;
      -webkit-border-radius: 0.8em;
      color: #ffffff;
      display: inline-block;
      font-weight: bold;
      line-height: 1.6em;
      margin-right: 5px;
      text-align: center;
      width: 1.6em;
      height: 1.6em;
    }
  }
  &__downloadBTN {
    margin-bottom: $base * 6;
  }
}
