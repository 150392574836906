@import '../../../styles/config/config.scss';
.dropzone * {
  font-family: 'Open Sans';
}
.dropzone {
  &__wrapper {
    outline: none;
    cursor: pointer;
    border: 1px dashed #7a89a7;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    padding: 18.5px 18.5px;
    min-width: 360px;
    width: 100%;
    height: 80px;
  }
  &__info {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #2b39b9;
    outline: none;
    margin-bottom: $base;
  }
  &__action {
    float: right;
    transform: translateY(24%);
  }
  &__icon {
    margin-right: $base * 3;
  }
  &__fileName {
    margin-left: $base * 5;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #282828;
    outline: none;
    margin-bottom: $base;
  }
  &__format {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #7a89a7;
  }
}
.title {
  margin-left: 24px;
}
