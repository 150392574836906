@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import './normalize';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~font-awesome/css/font-awesome.min.css';
@import './config/config.scss';

.example-enter {
  opacity: 0;
}

.example-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.example-exit {
  opacity: 1;
}

.example-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.print__hide {
  display: none;
}

body {
  color: #282828 !important;
}

@media print {
  @page {
    size: 816px 1054px landscape;
    padding: 0px !important;
    background-color: #ffffff !important;

    /* this affects the margin in the printer settings */
    margin: 0px !important;
  }
}

.pageBreak {
  display: none;
}

@media print {
  html,
  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    background-color: #ffff !important;
    margin: 0px !important;
    padding: 0px !important;
    width: 816px !important;
    max-width: 816px !important;
    min-width: 816px !important;

    overflow: initial !important;
  }
}

@media print {
  .print__hide {
    display: block;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

.SlideIn-enter {
  transform: translateX(30vw);
  -webkit-transform: translateX(30vw);
  -moz-transform: translateX(30vw);
  -o-transform: translateX(30vw);
  -ms-transform: translateX(30vw);
  opacity: 0;
}

.SlideIn-enter.SlideIn-enter-active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transition: all 1.12s linear;
}
.SlideIn-enter {
  opacity: 0;
  transform: translateX(30vw);
  -webkit-transform: translateX(30vw);
  -moz-transform: translateX(30vw);
  -o-transform: translateX(30vw);
  -ms-transform: translateX(30vw);
}
.SlideIn-enter.SlideIn-enter-active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transition: all 0.4s linear 0.4s;
}
.SlideIn-exit {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
}
.SlideIn-exit.SlideIn-exit-active {
  opacity: 0;
  transform: translateX(-30vw);
  -webkit-transform: translateX(-30vw);
  -moz-transform: translateX(-30vw);
  -o-transform: translateX(-30vw);
  -ms-transform: translateX(-30vw);
  transition: all 0.4s linear;
}
.page-fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}
.page-fade-enter.page-fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.page-fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.page-fade-exit.page-fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}

.content-switching-enter {
  height: 0;
  overflow: hidden;
}

.content-switching-enter-active {
  height: 100px;
  transition: height 300ms ease;
}

.content-switching-enter-done {
  height: 100px;
}

.content-switching-exit {
  height: 100px;
}
.content-switching-exit-active {
  height: 0;
  transition: height 300ms ease;
  overflow: hidden;
}

.content-switching-exit-done {
  height: 0;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 450ms, transform 450ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 450ms, transform 450ms;
}

.arrow-down-anim-enter {
  transform: rotate(0deg);
}

.arrow-down-anim-enter-active {
  transform: rotate(180deg);
  transition: 0.35s ease-in-out;
}

.arrow-down-anim-exit {
  transform: rotate(180deg);
}
.arrow-down-anim-enter-done {
  transform: rotate(180deg);
}

.arrow-down-anim-exit-active {
  transform: rotate(0deg);
  transition: 0.35s ease-in-out;
}

.fileInput {
  position: absolute;
}

.fileInput-enter {
  opacity: 0;
  transform: scale(1.1);
}

.fileInput-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 250ms, transform 200ms;
}

.fileInput-exit {
  opacity: 1;
  transform: scale(1);
}

.fileInput-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 250ms, transform 250ms;
}

.footer {
  position: absolute;
}

.footer-enter {
  opacity: 0;
  transform: scale(1.1);
}

.footer-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 400ms, transform 400ms;
}

.footer-exit {
  opacity: 1;
  transform: scale(1);
}

.footer-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 400ms, transform 380ms;
}

.fade-enter-active {
  max-height: 100% !important;
  position: absolute;
  transition: all 0.5ms;
  animation: fade-in 350ms forwards;
}
.fade-exit-active {
  position: absolute;
  max-height: 100% !important;
  transition: all 0.5ms;
  animation: fade-out 350ms forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(-1350px);
  }
  100% {
    opacity: 1;

    transform: translateX(0);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  80% {
    opacity: 0;
  }
  100% {
    transform: translateX(1350px);
  }
}
.MuiSkeleton-pulse {
  background-color: #eff2f7 !important;
}
.MuiRadio-colorPrimary.Mui-checked {
  color: #52b36b !important;
}
.Mui-disabled > span > span {
  color: hsla(220, 20%, 57%, 0.293) !important;
}
span.material-icons.MuiIcon-root {
  color: #7a89a7;
}
span.MuiTypography-root.MuiTypography-caption {
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #7a89a7 !important;
}
input#date-picker-inline::placeholder {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #282828 !important;
  opacity: 100 !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #7a89a7 !important;
}
.MuiInputBase-input {
  color: #282828 !important;
}
/* menu */
.ant-menu-dark.ant-menu-inline {
  background-color: #2f4050 !important;
}
.MuiTypography-body1 {
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #282828 !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #2f4050 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #2f4050 !important;
}

.select-dropdown {
  cursor: pointer;
  border: 2px solid #eff2f7;
  padding: 8px;
  color: #232b95;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  outline: none;
  &:hover {
    box-shadow: 0px 4px 15px rgba(43, 57, 185, 0.15) !important;
  }

  &:focus {
    outline: none;
  }
}

.ibox-content {
  display: flex;
  flex: 1 1;
  background-color: #ffffff;
  color: inherit;
  padding: 0 30px 5px 30px;
  background: #ffffff;
  // box-shadow: 0px 2px 15px rgb(0 0 0 / 5%);
  border-radius: 4px;
}

.MuiDialog-paperWidthSm {
  min-width: 360px;
  max-width: 100% !important;
}
.MuiFormLabel-root.Mui-error {
  color: #d01e1e !important;
}
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #d01e1e !important;
}
.MuiFormHelperText-root.Mui-error {
  color: #d01e1e !important;
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-size: 12px;
  line-height: 16px !important;
  font-weight: 400 !important;
}
.makeStyles-root-2,
.jss4 {
  flex-grow: 0 !important;
}
.MuiPaper-elevation2 {
  display: grid !important;
  grid-template-rows: 1fr auto auto;
  box-shadow: none !important;
  flex: 1;
}
.MuiPaper-root {
  display: flex;
  flex-direction: column;
  // flex: 1;
}
#analyzecu-app {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.echarts-tooltip {
  max-width: 600px !important;
  white-space: normal !important;
}
.MuiTypography-alignCenter {
  font-weight: 400 !important;
  color: #282828 !important;
  font-family: 'Open Sans' !important;
  font-style: normal;
}
.MuiPickersCalendarHeader-dayLabel {
  color: #7a89a7 !important;
  font-weight: 600 !important;
  font-family: 'Open Sans' !important;
}
.MuiTypography-colorInherit {
  font-weight: 600 !important;
  color: #282828 !important;
  font-family: 'Open Sans' !important;
  font-style: normal;
}
.MuiTypography-body2 {
  font-weight: 600;
  font-family: 'Open Sans' !important;
  font-style: normal;
}
.jss14,
.MTablePaginationInner-root-14 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}
* {
  font-weight: 600;
  font-family: 'Open Sans';
  font-style: normal;
}
#date-picker-inline-label {
  color: #7a89a7;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #c2cee0;
}
html {
  padding: 0;
}
.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 0.8s;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
body {
  background: #f5f5fb;
}

.MuiDialog-paperScrollPaper {
  top: 8%;
  max-height: 102% !important;
}

.MuiDialog-container {
  align-items: baseline !important;
  height: 85% !important;
}
.MuiToolbar-gutters {
  padding: 0 !important;
}
.MuiButton-label {
  font-size: 12px;
  font-weight: 600 !important;
  font-family: 'Open Sans' !important;
  font-style: normal !important;
}
.MuiTablePagination-input {
  position: absolute !important;
}
.selectdiv {
  position: relative;
  float: left;
}

.selectdiv:after {
  content: '\f078';
  font: normal normal normal $base * 3/1 FontAwesome;
  color: $secondary-text-color;
  right: $base * 4.5;
  top: 18px;
  height: 34px;

  position: absolute;
  pointer-events: none;
}
/* IE11 hide native button (thanks Matt!) */
select::-ms-expand {
  display: none;
}
.selectdiv select {
  border: none !important;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 244px;
  height: 48px;
  appearance: none;
  display: block;
  background-image: none;
  -ms-word-break: normal;
  word-break: normal;
}
.MuiIconButton-root {
  padding: 0;
}
.MuiSelect-select.MuiSelect-select {
  color: #7a89a7 !important;
}
.MuiTablePagination-root:last-child {
  border: none !important;
}

.status__0 {
  color: #f4ad3d;
}
.status__1 {
  color: #f4ad3d;
}
.MTableToolbar-root-5 {
  padding: 8px !important;
}
.MuiTableSortLabel-root {
  color: #7a89a7 !important;
}
.status__2 {
  color: #d01e1e;
}
.status__3 {
  color: #52b36b;
}
.MuiTypography-h6 {
  color: #282828 !important;
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
}
.MuiTableCell-root {
  color: #282828 !important;
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
}
.tooltip-div {
  z-index: 111;
  padding: $base * 2 $base * 4;
  font-size: 10px;
  text-align: left;
  min-width: 150px;
  height: 100%;
  color: #ffffff;
}
.tooltip-month {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  color: #eff2f7;
}
.tooltip-value {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}
.tooltip-text {
  margin-top: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.8);
}
h3 {
  text-align: center;
  line-height: 33px;
}

div.box {
  height: 100px;
  width: 200px;
  background-color: #eee;
  border: 1px solid #aaa;
  position: relative;
  top: 0px;
  left: 0px;
  border-radius: 7px;
}

.box:before {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  height: 0;
  width: 0;
  border: 10px solid transparent;
  z-index: 1;
}

.box:after {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  height: 0;
  width: 0;
  border: 10px solid transparent;
  z-index: 2;
}

.box:before {
  border-right-color: #aaa;
}

.box:after {
  border-right-color: #eee;
}

.spinner-align {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.MuiDialogActions-root {
  padding: $base * 5 !important;
  padding-top: 0 !important;
}
.MuiDialogContent-root {
  border-top: 1px #eff2f7 solid;
}

:root {
  --color-white: #ffffff;
  --color-black: #282828;
}
@mixin openSans {
  font-family: 'Open Sans', sans-serif;
}
//Normalize Box Sizing
*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
html {
  box-sizing: border-box;
}

body {
  @include openSans;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-weight: $fw-medium;
}
h1,
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 16px;
}
//ToDo replace this to breadcrumb component after navigation migration
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: $grey-text-color;
  content: '/';
}
.MuiButtonBase-root {
  vertical-align: top !important;
}
.MuiButton-contained {
  box-shadow: none !important;
}
//
.title {
  padding: 13px;
}
.boxes {
  margin: 20px;
  margin-top: 150px;
  background-color: rgb(198, 177, 198);
  overflow: hidden;
  height: 200px;
  max-height: 35vh;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.grids {
  max-width: 50%;
  margin: auto;
}

.box1,
.box2,
.box3,
.box4,
.box5,
.box6,
.box7,
.box8,
.box9,
.box10 {
  background-color: #ffffff;
  overflow: hidden;
  height: 200px;
  max-height: 35vh;
  -webkit-box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.box {
  margin: 20px;
}

.grid {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.grid > div {
  flex-basis: calc(50% - 40px);
}

.boxy {
  width: 300px;
  background: green;
  padding: 50px;
  margin-left: auto;
  margin-right: auto;
}
.boxy:hover {
  animation: boxyRotation 4s infinite linear;
}

@keyframes boxyRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.black-background {
  background-color: red !important;
  font-size: 5vh;
}
