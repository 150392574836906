.content {
  width: 100%;
  &__main {
    margin-bottom: 20px;
  }
  &__label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    color: #282828;
    margin-left: 1px;
    margin-bottom: 8px;
  }
}
.btn {
  font-size: 14px;
}
.areaBlock {
  display: flex;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #7a89a7;
  &__mainText {
    margin-left: 4px;
    color: #52b36b;
  }
}
