.legend {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &__sign {
    width: 18px;
    height: 6px;
    display: inline-block;
    background: #232b95;
    margin-left: 5px;
    border-radius: 1px;
    &__comparison {
      background: #52b36b;
      color: #52b36b;
    }
  }

  &__text {
    width: max-content;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #282828;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
