@import '../../../styles/config/config.scss';
.wrapper {
  height: 100%;
  padding: $base * 7 $base * 5 $base * 5;
  display: flex;
  flex-direction: column;
  &__right > div {
    float: right;
  }
  &__heading {
    &__margin {
      margin: 0 0 $base * 5;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  &__main {
    color: inherit;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  // Only apply these styles when headerActions is present
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }
  &__actions {
    display: flex;
    gap: 16px;
    align-items: center;
  }
}

// These styles are specific to CDFI page components
.apiLink {
  color: #2b39b9;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    text-decoration: underline;
  }
}

.resourcesDropdown {
  color: #2b39b9;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 8px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #2b39b9;
  }
}
