.dropdown {
  margin-top: 4px;
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 100%;
  width: 100%;
  padding: 8px;
  &__item {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #2b39b9;
    width: 100%;
    padding: 12px;
    cursor: pointer;
  }
  &__item:hover {
    background: ghostwhite;
  }
}
.button {
  width: 100%;
  cursor: inherit;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 40px;
  &__done {
    background: #232b95;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: center;
    color: #ffffff;
  }
  &__loading {
    background: '#52B36B';
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &__text {
      margin-top: 8px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
      width: 100%;
      text-align: center;
    }
    &__text:after {
      margin: 1px;
      overflow: hidden;
      position: absolute;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4, end) 900ms infinite;
      animation: ellipsis steps(4, end) 900ms infinite;
      content: '\2026'; /* ascii code for the ellipsis character */
      width: 0px;
    }
  }
  &__loader {
    margin-top: 4px;
    transition: all 0.6s ease-in-out;
    background: #abdab7;
    height: 6px;
    border-radius: 0 0 0 4px;
  }

  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
}
