.numberBlock {
  background: #ffffff;
  box-shadow: 0px 2px 15px rgb(0, 0, 0 / 5%);
  border-radius: 4px;
  height: 216px;
  padding: 30px 40px;
  margin-bottom: 20px;
  display: flex;
  &__total {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 88%;
    &__label {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #232b95;
    }
    &__value {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 49px;
      display: flex;
      align-items: center;
      color: #282828;
    }
  }
}
