.chart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 145px;
    padding: 0 20px;
    &__value {
      font-family: 'Open Sans';
      font-style: normal;
      margin: 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: #282828;
    }
    &__title {
      margin-bottom: 6px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 8px;
      line-height: 10px;
      color: #232b95;
    }
  }
}
.legend {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 20px;
  align-items: center;
  &__wrapper {
    border-radius: 4px;
    display: flex;

    &__title {
      height: 12px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 8px;
      line-height: 10px;
      color: #282828;
    }
    &__legend {
      * {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 8px;
        line-height: 10px;
      }
    }
  }
}
.split {
  display: flex;
  justify-content: space-between;
  &__element {
    width: 32%;
    height: 54px;
    background: rgba(239, 242, 247, 0.55);
    box-shadow: 0px 0.92769px 6.95767px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-radius: 4px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    &__value {
      font-family: 'Open Sans';
      font-style: normal;
      margin: 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: #282828;
    }
    &__text {
      margin: 0;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 6px;
      line-height: 10px;
      letter-spacing: 0.0954545px;
      color: #7a89a7;
    }
    &__title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 8px;
      line-height: 10px;
    }
  }
}

.tooltip {
  padding: 16px 20px 20px 20px;
  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &__text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 11px;
      letter-spacing: 0.3px;
      color: #000000;
      margin: 0;
      padding: 0;
    }
    &__title {
      margin-bottom: 6px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 8px;
      color: #232b95;
    }
  }
}

.number {
  padding: 14px 20px;
  display: flex;
  height: 94px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-direction: row;
  &__element {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    &__title {
      height: 12px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 8px;
      line-height: 10px;
      color: #282828;
    }
    &__text {
      font-style: normal;
      font-weight: 600;
      font-size: 6px;
      line-height: 9px;
      letter-spacing: 0.0875622px;
      color: #232b95;
    }
    &__value {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: #282828;
    }
  }
}
.block {
  width: 100%;
  background: rgba(239, 242, 247, 0.55);
  box-shadow: 0px 0.92769px 6.95767px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  &__text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 11px;
    letter-spacing: 0.3px;
    margin: 0;

    color: #000000;
  }
  &__title {
    margin: 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.3px;
    color: #282828;
  }
}

.padding {
  width: 100%;
  padding: 3px;
}
.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  &__left {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    &__page {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 33px;
      letter-spacing: 0.3px;
      color: #282828;
    }
    &__title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.3px;
      color: rgba(40, 40, 40, 1);
    }
  }
  &__right {
    align-items: flex-end;
  }
}
