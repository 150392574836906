@import '../../../styles/config/config.scss';
.wrapper {
  height: 100%;
  padding: $base * 7 $base * 5 $base * 5;
  display: flex;
  flex-direction: column;
  &__right > div {
    float: right;
  }
  &__heading {
    &__margin {
      margin: 0 0 $base * 5 0;
    }
  }
  &__main {
    color: inherit;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
