.button {
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 48px;
  width: 90px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  text-decoration: none;

  &__text {
    text-decoration: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #2b39b9;
  }
}

.button:hover {
  text-decoration: none;
  color: #191960;
  filter: drop-shadow(0px 2px 15px rgba(122, 137, 167, 0.25%));
  background: white;
  * {
    color: #191960;
  }
}
