.item {
  color: #2db295;
}

.modal {
  padding: 30px 40px;
  width: 482px;
  height: 272px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  &__header {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #282828;
  }
  &__content {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #282828;
  }
  &__margin {
    margin: 12px;
  }
}
