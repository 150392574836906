.loadingLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.widget {
  font-family: 'Open Sans';
  font-style: normal;
  margin-bottom: 20px;
  display: flex;
  &__wrapper {
    background: #ffffff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    height: 216px;
    padding: 20px 40px;
    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #282828;
    }
    &__content {
      height: 88%;
      display: flex;
      flex-direction: row;
      align-content: center;
      &__label {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #232b95;
      }
      &__value {
        font-weight: bold;
        font-size: 36px;
        line-height: 49px;
        display: flex;
        align-items: center;
        color: #282828;
      }
    }
  }
}
