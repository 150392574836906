.tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
  &__input {
    position: relative;
    align-items: flex-start;

    min-height: 40px;
    width: 100%;
    padding: 0 8px;
    border: 1.5px solid #c2cee0;
    max-height: 300px;
    display: flex;
    overflow: auto;
    flex-wrap: nowrap;
    flex-direction: column;
    border-radius: 4px;
    &:focus-within {
      border: 1.5px solid #c2cee0;
    }
    input {
      flex: 1;
      border: none;
      height: 40px;
      font-size: 14px;
      padding: 4px 0 0 0;
      &:focus {
        outline: transparent;
      }
    }
  }
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 4px;
  margin: 0 8px 8px 0;
  background: #52b36b;
  &__title {
    margin-top: 3px;
  }
  &__closeIcon {
    display: flex;
    justify-content: space-around;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 12px;
    margin-left: 8px;
    color: #fff;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
  }
}
