@import '../../../styles/config/config.scss';
.emptyState {
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  text-align: center;
  -webkit-box-pack: center;
  padding-top: 40px;
  padding-bottom: 40px;
  padding: 20px;
  border-radius: 4px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-color: white;
  &__title {
    font-style: normal;
    font-weight: $fw-medium;
    font-size: 16px;
    line-height: 22px;
    color: $primary-text-color;
    margin-bottom: $base * 2;
  }
  &__img {
    margin-bottom: $base * 3;
  }
  &__btn {
    display: inline-flex;
  }
  &__text {
    width: 55%;
    margin: 0 auto;
    color: $grey-text-color;
    line-height: 1.5;
    font-size: $base * 3;
    margin-top: $base * 2;
    margin-bottom: $base * 4;
    font-weight: 400;
  }
}
