@import '../../../styles/config/config.scss';

.dialog {
  &__img {
    text-align: center;
    margin-top: $base * 5;
    margin-bottom: $base * 6;
  }
  &__text {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    width: 334px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #282828;
    margin-bottom: 30px;
  }
  &__action {
    justify-content: center !important;
  }
}
