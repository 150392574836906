.alert {
  background: rgba(217, 75, 75, 0.1);
  border: 1px solid #d01e1e;
  border-radius: 4px;
  padding: 14px;
  min-height: 70px;
  display: flex;
  align-items: center;
  &__text {
    max-width: 280px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    margin: 0;
    color: #d01e1e;
    button {
      background-color: transparent;
      margin: 0;
      padding: 0;
      border: none;
      color: #2b39b9;
      text-decoration: underline;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
    }
  }
  &__icon {
    margin-right: 16px;
  }
}
