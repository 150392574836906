.total {
  padding: 0 5px;
}
.loading {
  &__sm {
    height: 24px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    &__block {
      display: flex;
      flex-direction: row !important;
      justify-content: space-between !important;
      &__item {
        padding: 5px 20px;
        width: 100%;
        max-width: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &__left {
          width: 100%;
          max-width: 140px;
        }
      }
    }
    &__legend {
      display: flex;
      width: 50%;
      max-width: 400px;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &__text {
    margin-left: 20px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #282828;
  }
}
.breadcrumbs {
  &__wrapper {
    display: flex;
    flex-direction: row;
    &__leftElement {
      margin-right: 48px;
    }
  }
}
.tooltip {
  &__text {
    color: #ffff;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
  }
  &__title {
    font-size: 18px;
    margin-bottom: 8px;
    font-weight: 700;
  }
}
.border:after {
  content: '';
  height: 92%; //You can change this if you want smaller/bigger borders
  width: 3px;
  bottom: 0;
  position: absolute;
  left: 0;
  background-color: rgb(241, 241, 241);
}

.ownerCosts {
  top: -20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.comparison {
  &__block {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &__label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #7a89a7;
      margin-bottom: 4px;
    }
    &__text {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #1a56c0;
    }
  }
}

.chart {
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 20px 25px;
  margin-bottom: 20px;
  display: flex;
  height: 314px;
}

.chartSubTitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #282828;
  &__margin {
    margin-left: 6px;
  }
  &__secondary {
    color: #7a89a7;
  }
}

.context {
  &__padding {
    float: right;
    width: 298px;
    padding-left: 4px;
    &__child {
      float: right;
      padding: 0 4px;
    }
  }
}

.dropdown {
  top: 58px;
  width: 99%;
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 20px;
  z-index: 1;
  position: absolute;
  background-color: #fff;
  &__action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    &__margin {
      margin-left: 8px;
    }
  }
  &__underline {
    border: 1px solid #eff2f7;
    width: 100%;
    margin-top: 10px;
  }
}

.legend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -8px;
  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 20px;
    &__text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #282828;
      white-space: pre-line;
    }
    &__subText {
      color: #7a89a7;
    }
  }
}

@media only screen and (max-width: 991px) {
  .total {
    padding: 0 15px;
  }
}
