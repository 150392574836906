.button {
  width: 234px;
  cursor: inherit;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 40px;
  &__done {
    background: #232b95;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: center;
    color: #ffffff;
  }
  &__loading {
    background: '#52B36B';
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &__text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      width: 100%;
      margin-top: 2px;
      text-align: center;
    }
    &__text:after {
      margin: 2px 1px 0 1px;
      overflow: hidden;
      position: absolute;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4, end) 900ms infinite;
      animation: ellipsis steps(4, end) 900ms infinite;
      content: '\2026'; /* ascii code for the ellipsis character */
      width: 0px;
    }
  }
  &__loader {
    margin-top: 4px;
    transition: all 0.6s ease-in-out;
    background: #abdab7;
    height: 4px;
    border-radius: 0 0 0 4px;
  }

  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
}

.alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 100%;
  height: 80px;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    &__title {
      margin-left: 12px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: 0.3px;
      color: #2b39b9;
    }
    &__text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #808385;
    }
  }

  &__close {
    display: flex;
    align-items: flex-start;
    height: 40px;
    &__btn {
      display: flex;
      padding: 0;
      background: transparent;
      border: none;
    }
  }
}
