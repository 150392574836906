.widget_header {
  display: table-header-group;
  text-align: initial;
}
/* WIDGETS */
.navy-bg {
  background-color: #1ab394;
  color: #ffffff;
}
.navy-bg-secondary {
  background-color: red;
  color: #ffffff;
}

.widget {
  padding: 15px 20px;
  margin-bottom: 10px;
  height: 100%;
  display: table;
  width: 100%;
}

.widget.style1 h2 {
  font-size: 30px;
}

.widget h2,
.widget h3 {
  margin-top: 5px;
  margin-bottom: 0;
}

.widget-text-box {
  padding: 20px;
  border: 1px solid #e7eaec;
  background: #ffffff;
}

.widget-head-color-box {
  border-radius: 5px 5px 0 0;
  margin-top: 10px;
}

.widget .flot-chart {
  height: 100px;
}

.widget-align {
  display: table-cell;
  vertical-align: middle;
}
