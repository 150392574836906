.autocomplete {
  display: flex;
  min-height: 40px;
  align-items: center;
  &__input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 24px;
    width: 100%;
    padding: 0 8px;
    border-radius: 4px;
    // &:focus-within {
    //   background: #ffffff;
    //   border: 1px solid #626c79;
    //   box-sizing: border-box;
    //   border-radius: 4px;
    // }
    input {
      flex: 1;
      height: 24px;
      border: none;
      font-size: 14px;
      padding: 4px 0 0 0;
      &:focus {
        outline: transparent;
        background: green;
      }
    }
  }
  &__noSuggestions {
    color: #999;
    padding: 8px;
  }
  &__suggestions {
    width: 100%;
    left: -2px;
    height: 100%;
    max-height: 322px;
    margin-top: 8px;
    background: #ffffff;
    border: 1px solid #c2cee0;
    box-sizing: border-box;
    list-style: none;
    overflow-y: auto;
    padding-left: 0;
    border-radius: 4px;
    &__suggestions li:not(:last-of-type) {
      border-bottom: 1px solid #999;
    }
    &__item {
      cursor: pointer;
      padding: 8px;
    }
    &__item:hover {
      background: #c2cee0;
    }
    &__active {
      background: #dadada;
      cursor: pointer;
    }
  }
}

.autocomplete li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}
