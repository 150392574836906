/*
============================================================================
COLD Variables

This is where you will use variables that can be used with SASS

Spacers, Breakpoints
=============================================================================
*/

//** Spacers **//
$spacer-xs: 4px;
$spacer-s: 8px;
$spacer-m: 16px;
$spacer-l: 24px;
$spacer-xl: 32px;

//** Grid Breakpoints and Class Names **//
//Do not change the variable names
$grid-medium: 750px;
$grid-large: 990px; ///////////
$grid-widescreen: 1400px;

$small: 'small';
$medium: 'medium';
$medium-down: 'medium-down';
$medium-up: 'medium-up';
$large: 'large';
$large-down: 'large-down';
$large-up: 'large-up';
$widescreen: 'widescreen';

// The `$breakpoints` list is used to build our media queries.
// You can use these in the media-query mixin.
$breakpoints: (
  $small '(max-width: #{$grid-medium - 1})',
  $medium '(min-width: #{$grid-medium}) and (max-width: #{$grid-large - 1})',
  $medium-down '(max-width: #{$grid-large - 1})',
  $medium-up '(min-width: #{$grid-medium})',
  $large '(min-width: #{$grid-large}) and (max-width: #{$grid-widescreen - 1})',
  $large-down '(max-width: #{$grid-widescreen - 1})',
  $large-up '(min-width: #{$grid-large})',
  $widescreen '(min-width: #{$grid-widescreen})'
);

//Used for fluid Typography
$min-width: 375px;
$max-width: 1400px;

//Main variables
$primary-text-color: #282828;
$secondary-text-color: #232b95;
$grey-text-color: #7a89a7;
$blue-text-color: #2b39b9;
$base-green-color: #7bd5a7;
$base-red-color: #f0626e;
$brand-red-color: #d03340;
$base: 4px;

//Font
$fw-default: 400;
$fw-regular: 500;
$fw-medium: 600;
$fw-bold: 700;

//Padding
$padding-vertical-xs: $base * 5;
$padding-vertical-sm: $base * 15;
$padding-vertical: $base * 30;
