.middleBox {
  max-width: 400px;
  z-index: 100;
  margin: 0 auto;
  padding-top: 40px;
}

.middleBox h1 {
  font-size: 170px;
}
