@import '../../../styles/config/config.scss';
.breadcrumb {
  padding: 0;
  margin: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: $base * 6;
  &__block {
    margin-top: $base;
    display: flex;
    flex-direction: row;
    &__element {
      display: flex;
      align-items: center;
    }
    &__title {
      color: $primary-text-color;
      font-weight: $fw-bold;
    }
  }
  &__right {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &__source {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 12px;
    line-height: 20px;
    font-weight: $fw-medium;
    &__item > a {
      color: $grey-text-color;
      &__active {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #1a56c0;
      }
    }
    &__item {
      color: $grey-text-color;
      &__active {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #282828;
      }
    }
  }
}
